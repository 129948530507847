
// Base class
.tooltip {
  &.show { opacity: $tooltip-opacity-alt; }
}

.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: $tooltip-arrow-color-alt;
    }
  }
}

.bs-tooltip-right {
  .arrow {
    &::before {
      border-right-color: $tooltip-arrow-color-alt;
    }
  }
}

.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: $tooltip-arrow-color-alt;
    }
  }
}

.bs-tooltip-left {
  .arrow {
    &::before {
      border-left-color: $tooltip-arrow-color-alt;
    }
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  color: $tooltip-color-alt;
  background-color: $tooltip-bg-alt;
}
