
// Headings

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $headings-color-alt;
}


// Horizontal rules

hr {
  border-top: $hr-border-width solid $hr-border-color-alt;
}


// Emphasis

mark,
.mark {
  background-color: $mark-bg-alt;
}
