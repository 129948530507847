
.popover {
  background-color: $popover-bg-alt;
  border: $popover-border-width solid $popover-border-color-alt;
  @include box-shadow($popover-box-shadow-alt);
}

.bs-popover-top {
  > .arrow {
    &::before {
      border-top-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-top-color: $popover-arrow-color-alt;
    }
  }
}

.bs-popover-right {
  > .arrow {
    &::before {
      border-right-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-right-color: $popover-arrow-color-alt;
    }
  }
}

.bs-popover-bottom {
  > .arrow {
    &::before {
      border-bottom-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-bottom-color: $popover-arrow-color-alt;
    }
  }

  .popover-header::before {
    border-bottom: $popover-border-width solid $popover-header-bg-alt;
  }
}

.bs-popover-left {
  > .arrow {
    &::before {
      border-left-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-left-color: $popover-arrow-color-alt;
    }
  }
}

// Offset the popover to account for the popover arrow
.popover-header {
  color: $popover-header-color-alt;
  background-color: $popover-header-bg-alt;
  border-bottom: $popover-border-width solid darken($popover-header-bg-alt, 5%);
}

.popover-body {
  color: $popover-body-color-alt;
}
