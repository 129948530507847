
// Interactive list items

.list-group-item-action {
  color: $list-group-action-color-alt;

  // Hover state
  @include hover-focus() {
    color: $list-group-action-hover-color-alt;
    background-color: $list-group-hover-bg-alt;
  }

  &:active {
    color: $list-group-action-active-color-alt;
    background-color: $list-group-action-active-bg-alt;
  }
}

// Individual list items

.list-group-item {
  color: $list-group-color-alt;
  background-color: $list-group-bg-alt;
  border: $list-group-border-width solid $list-group-border-color-alt;

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color-alt;
    background-color: $list-group-disabled-bg-alt;
  }

  &.active {
    color: $list-group-active-color-alt;
    background-color: $list-group-active-bg-alt;
    border-color: $list-group-active-border-color-alt;
  }
}

// Contextual variants

@each $color, $value in $theme-colors-alt {
  @include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}
